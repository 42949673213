import { Language } from "./options";

// if there was no specified fallback for a country we use en_DEV.json by default.
export const FallbackCountryLanguages: Record<string, Language | undefined> = {
    AT: "de-INT",
    CH: "de-INT",
    DE: "de-INT",
    HU: "de-INT",
    SI: "de-INT",
};
