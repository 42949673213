<script lang="ts" setup>
import { CSSProperties } from "vue";
import logoTopBar from "../../../assets/images/logo_top_bar.svg";
import { Icon } from "../../icons";
import WuxIcon from "../WuxIcon/WuxIcon.vue";
import WuxNewVersionHint from "../WuxNewVersionHint/WuxNewVersionHint.vue";

export type BackAction = { onClick: () => void; icon: Icon; href?: string };

const props = defineProps<{
    title: string;
    isLoading?: boolean;
    backAction?: BackAction;
    newVersionAvailable?: boolean;
    style?: CSSProperties;
}>();
const onBackClick = (e: MouseEvent) => {
    // The back link has an href property, to be able to open the link with "right-click/open in another tab".
    // We need to prevent opening that link on mouse click to use the vue router instead of opening a new page.
    e.preventDefault();
    props.backAction?.onClick();
};
</script>

<template>
    <div class="wux-top-bar-wrapper hidden-print">
        <div class="wux-top-bar" :style="props.style">
            <!-- left section -->
            <div class="wux-top-bar__section wux-top-bar__left">
                <!-- header logo / back button -->
                <div class="wux-top-bar__left__item" v-if="props.backAction">
                    <a :href="props.backAction.href" @click="onBackClick" class="wux-top-bar__back-link">
                        <WuxIcon class="wux-top-bar__back-button" :src="props.backAction.icon" />
                    </a>
                </div>
                <!-- header logo with title -->
                <div v-if="props.title" class="wux-top-bar__left__item wux-top-bar__title-container">
                    <div class="wux-top-bar__logo">
                        <img :src="logoTopBar" :alt="props.title" />
                    </div>
                    <div class="wux-top-bar__title">
                        {{ props.title }}
                    </div>
                </div>
                <!-- 'load new version' button -->
                <div class="wux-top-bar__left__item wux-top-bar__load-button" v-if="props.newVersionAvailable">
                    <WuxNewVersionHint />
                </div>
            </div>
            <!-- right section -->
            <div class="wux-top-bar__section wux-top-bar__right">
                <slot name="top-bar-right" />
            </div>
            <!-- loading indicator -->
            <div v-if="props.isLoading" class="wux-top-bar__loading" />
        </div>
    </div>
</template>
<style lang="scss">
@use "../../../assets/styles/variables.scss";
@use "../../../assets/styles/mixins.scss";

.wux-top-bar-wrapper {
    height: var(--wux-top-bar-height);
}
.wux-top-bar {
    --layout-background-override: var(--exp-brand-gradient-ltr);
    --wux-top-header-content-height: 20px;
    $gap: 1rem;

    background: var(--layout-background-override);
    position: fixed;
    z-index: variables.z("top-bar");
    top: 0;
    left: 0;
    width: 100%;
    height: var(--wux-top-bar-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $gap;
    box-sizing: border-box;
    padding: 0 0.5rem;
    color: var(--wux-color-white);
    &__section {
        display: flex;
        align-items: center;
        height: 100%;
    }
    &__left {
        &__item {
            position: relative;
            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 0.5rem;
            }

            &:not(:first-child) {
                padding: 0 $gap;
                &::before {
                    position: absolute;
                    left: 0;
                    content: "";
                    width: 1px;
                    height: var(--wux-top-header-content-height);
                    background-color: var(--wux-color-white);
                }
            }
        }
    }
    &__back-link {
        line-height: 0;
        text-decoration: none;
        color: var(--wux-color-white);
        padding: 7px 0.75rem; // 7px is used to add 1 px gap on top and botton when it's keyboard focused
        &:hover {
            background-color: var(--wux-color-white-overlay);
        }
        &:focus-visible {
            outline: none;
            box-shadow:
                inset 2px 2px 0px white,
                inset -2px -2px 0px white;
        }
    }
    &__title-container {
        display: flex;
        gap: 1rem;
        min-width: 0;
    }
    &__logo {
        display: flex;
        padding-left: 0.25rem;
        img {
            width: var(--wux-top-header-content-height);
            height: var(--wux-top-header-content-height);
        }
    }
    &__title {
        color: var(--wux-color-white);
        font-size: 0.875rem;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &__load-button {
        @include mixins.media-query-max("s") {
            display: none;
        }
        .wux-button {
            --_wux-button-color: var(--wux-color-white);
            --_wux-button-border-color: var(--wux-color-white);
            &:hover {
                background: rgba(255, 255, 255, 0.2);
            }
        }
    }

    // loader
    &__loading {
        @include mixins.topLoadingBar();
    }
}
</style>
