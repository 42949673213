import { onUnmounted } from "vue";
import { type DatadogActions } from "../setup";
import { reportAction } from "../trace";

type ShortcutInfo = { code: string; alt?: boolean; ctrl?: boolean; shift?: boolean; meta?: boolean };

export type Shortcut<Mods = unknown> = {
    __: ShortcutInfo;
    toString: () => string;
    // eslint-disable-next-line @typescript-eslint/ban-types
} & ("alt" extends Mods ? {} : { alt: Shortcut<Mods | "alt"> }) &
    // eslint-disable-next-line @typescript-eslint/ban-types
    ("ctrl" extends Mods ? {} : { ctrl: Shortcut<Mods | "ctrl"> }) &
    // eslint-disable-next-line @typescript-eslint/ban-types
    ("shift" extends Mods ? {} : { shift: Shortcut<Mods | "shift"> }) &
    // eslint-disable-next-line @typescript-eslint/ban-types
    ("meta" extends Mods ? {} : { meta: Shortcut<Mods | "meta"> });

const createShortcut = (key: string): Shortcut<never> => {
    const code = key.length === 1 ? (isNaN(+key) ? `Key${key.toUpperCase()}` : `Digit${key}`) : key;
    return {
        __: { code },
        toString() {
            return serializeToKey(this.__);
        },
        get alt() {
            this.__.alt = true;
            return this;
        },
        get ctrl() {
            this.__.ctrl = true;
            return this;
        },
        get shift() {
            this.__.shift = true;
            return this;
        },
        get meta() {
            this.__.meta = true;
            return this;
        },
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Shortcuts: Record<string, Shortcut<never>> = new Proxy({} as any, {
    get(target, prop) {
        if (typeof prop === "symbol") return undefined; // if used as symbol (e.g. object key)
        return createShortcut(prop);
    },
});

const withModifier = (shortcut: Shortcut, modifier: "alt" | "ctrl" | "shift" | "meta"): Shortcut => {
    const clone = {
        // we need to proceed like this instead of using the spread syntax, as othwerwise ctrl, alt, ... will all become true
        __: { ...shortcut.__ },
        toString: shortcut.toString,
    };
    clone.__[modifier] = true;
    return clone;
};

const registeredShortcuts: Map<string, Array<(e: KeyboardEvent) => void>> = new Map();

const serializeToKey = ({ code, ctrl, alt, meta, shift }: ShortcutInfo): string =>
    [code, ctrl ? "c" : "", alt ? "a" : "", meta ? "m" : "", shift ? "s" : ""].join(",");

const serializeEvent = (e: KeyboardEvent): string =>
    serializeToKey({
        code: e.code,
        ctrl: e.ctrlKey,
        alt: e.altKey,
        shift: e.shiftKey,
        meta: e.metaKey,
    });

/**
 * Can be used to (re-)assign global shortcuts that don't require certain elements to be focused.
 *
 * ATTENTION: Inside of Vue components use rather ShortcutUtil.useGlobal.
 *
 * Usage:
 * ShortcutUtil.registerGlobal(Shortcuts.t.alt, () => { ... });
 */
const registerGlobal = (s: Shortcut, handler: (e: KeyboardEvent) => void): (() => void) => {
    const key = s.toString();
    if (!registeredShortcuts.has(key)) {
        registeredShortcuts.set(key, []);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    registeredShortcuts.get(key)!.push(handler);
    return () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const handlers = registeredShortcuts.get(key)!.filter((h) => h !== handler);
        if (handlers.length) {
            registeredShortcuts.set(key, handlers);
        } else {
            registeredShortcuts.delete(key);
        }
    };
};

// Will be initialized with the component setup
export const initGlobalShortcuts = (actions?: DatadogActions) => {
    // we use only a single listener for all handlers
    addEventListener("keydown", (e) => {
        const key = serializeEvent(e);
        const handlers = registeredShortcuts.get(key);
        if (!handlers) return;
        for (const handler of Array.from(handlers).reverse()) {
            handler(e);
            if (actions) reportAction(actions, key);
            if (e.defaultPrevented) {
                break;
            }
        }
    });
};

/**
 * Registers global shortcuts bound to the life-cycle of the component.
 *
 * In <script>:
 * ShortcutUtil.useGlobal(
 *   [Shortcuts.t.alt, () => { ... }],
 *   [Shortcuts.ArrowDown, () => { ... }]
 * );
 *
 * ATTENTION: You can register the same key multiple times and all handlers will be invoked in the reversed order of
 *            registration (or component nesting). As soon as some handler calls e.preventDefault() on the passed event
 *            the event will not be propagated to other handlers.
 */
export const useGlobal = (...defs: [Shortcut, (e: KeyboardEvent) => void][]) => {
    const cleanups = defs.map(([s, h]) => registerGlobal(s, h));
    onUnmounted(() => {
        cleanups.forEach((c) => c());
    });
};

/**
 * Can be used to create key down handlers on any kind of UI elements.
 *
 * In <script>:
 * const onKeyDown = ShortcutUtil.getKeyDownHandler(
 *   [Shortcuts.t.alt, () => { ... }],
 *   [Shortcuts.ArrowDown, () => { ... }]
 * );
 *
 * In <template>: @keydown="onKeyDown"
 */
export const getKeyDownHandler = (...defs: [Shortcut, (e: KeyboardEvent) => void][]) => {
    // The special matchers are different because they can match multiple events (therefore we can't compare serializations)
    const specialDefs = defs.filter(([s]) => Object.keys(matchers).some((key) => s.__.code === key));
    const normalDefs = defs.filter(([s]) => !Object.keys(matchers).some((key) => s.__.code === key));
    const normalShortcuts = new Map(normalDefs.map(([s, h]) => [s.toString(), h]));
    return (e: KeyboardEvent) => {
        const key = serializeEvent(e);
        // Fallback to checking any special matchers
        // We use findLast instead of find to take into account the shortcuts overrides
        const shortcutFn = normalShortcuts.get(key) ?? specialDefs.findLast(([s]) => matches(s, e))?.[1];
        shortcutFn?.(e);
    };
};

/**
 * These are special matchers for certain keys that are tricky to match.
 * All matcher keys should be prefixed by "_" to avoid collisions with existing codes.
 */
const matchers: Record<string, (e: KeyboardEvent) => boolean> = {
    // on either Plus on the Numpad or Plus with Alt-Key
    // on macOS Alt & Plus produces "±"
    _Plus: (e: KeyboardEvent) => (e.altKey ? ["+", "±"].includes(e.key) : e.key === "+"),
};

const matches = (s: Shortcut, e: KeyboardEvent) => {
    const code = matchers[s.__.code]?.(e) ? s.__.code : e.code;
    // Attention: Destructuring of the event data via ...e may not work in all browsers as the properties are accessed
    //            on computed getters.
    const { metaKey, altKey, ctrlKey, shiftKey } = e;
    return serializeEvent({ metaKey, altKey, ctrlKey, shiftKey, code } as KeyboardEvent) === s.toString();
};

const equal = (s1: Shortcut, s2: Shortcut) => s1.toString() === s2.toString();

export const ShortcutUtil = {
    getKeyDownHandler,
    useGlobal,
    registerGlobal,
    matches,
    equal,
    withModifier,
};
