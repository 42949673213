/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { type Endpoint, HTTPMethod, type OmitOptionalDeep } from "../../../src/runtime";
import { type ListFeatureTogglesRequest } from "../configs/ListFeatureTogglesRequest";
import { type ListFeatureTogglesResponse, ListFeatureTogglesResponseSchema } from "../configs/ListFeatureTogglesResponse";

export type ListConfigsFeatureTogglesQueryParams = OmitOptionalDeep<ListFeatureTogglesRequest, "">;

export const ListConfigsFeatureToggles: Endpoint<ListFeatureTogglesResponse, void, void, ListConfigsFeatureTogglesQueryParams> = {
    method: HTTPMethod.GET,
    path: "/wam/configs/feature-toggles",
    parser: ListFeatureTogglesResponseSchema.parse,
};
