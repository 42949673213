/*
 * Code generated by openapi-generator. DO NOT EDIT.
 */

import { z } from "zod";

export enum Section {
    SECTION_UNKNOWN = "SECTION_UNKNOWN",
    SECTION_SHARED = "SECTION_SHARED",
    SECTION_WSM = "SECTION_WSM",
    SECTION_IAM = "SECTION_IAM",
    SECTION_WAW = "SECTION_WAW",
    SECTION_REP = "SECTION_REP",
    SECTION_DHQ = "SECTION_DHQ",
    SECTION_SFO = "SECTION_SFO",
    SECTION_MIL = "SECTION_MIL",
    SECTION_TILL = "SECTION_TILL",
    SECTION_TAB = "SECTION_TAB",
    SECTION_XWH = "SECTION_XWH",
    SECTION_GRC = "SECTION_GRC",
    SECTION_DIS = "SECTION_DIS",
    SECTION_KEP = "SECTION_KEP",
    SECTION_SIC = "SECTION_SIC",
    SECTION_YWH = "SECTION_YWH",
    SECTION_ADR = "SECTION_ADR",
    SECTION_FWD = "SECTION_FWD",
    SECTION_EGIN = "SECTION_EGIN",
    SECTION_MMO = "SECTION_MMO",
    SECTION_FDI = "SECTION_FDI",
    SECTION_STW = "SECTION_STW",
    SECTION_INV = "SECTION_INV",
    SECTION_MDE = "SECTION_MDE",
    SECTION_SAP = "SECTION_SAP",
    SECTION_BDI = "SECTION_BDI",
    SECTION_YHQ = "SECTION_YHQ",
    SECTION_AMD = "SECTION_AMD",
    SECTION_WPC = "SECTION_WPC",
    SECTION_PAR = "SECTION_PAR",
}

export const SectionSchema = z.nativeEnum(Section).catch(Section.SECTION_UNKNOWN);
