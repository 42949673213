<script lang="ts" setup>
import { computed, ref, watch, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Section } from "@ui/clients/generated/wam/settings";
import {
    __,
    Icon,
    Icons,
    Shortcuts,
    useMsgFormatter,
    WuxDialog,
    WuxGuidedTourIntro,
    WuxGuidedTourStep,
    WuxLayout,
    WuxOfflineIndicator,
    WuxToast,
    WuxToastElementProps,
    WuxTopBarDropdown,
} from "@ui/components";
import { useUserStore } from "../auth/user";
import { LoadingStatus, useFeatureTogglesStore, useWawiConfigsStore } from "../configs/wawiConfigs";
import WawiConfigsWrapper from "../configs/WawiConfigsWrapper.vue";
import { WaWiCountry } from "../country/country";
import { getCountryOptions } from "../i18n/country/options";
import { LocationType, useFilteredLocations, WaWiLocation } from "../location/location";
import { LOCATION_SELECTOR_NAME } from "../location/router";
import { LocationLoadingStatus, useLocationsStore } from "../location/store";
import { CurrentModule } from "../module/current";
import { getModuleFullName, useAvailableModules, useCurrentModule, WaWiModule } from "../module/module";
import { type Module } from "../module/registry";
import { ModuleLoadingStatus, useModulesStore } from "../module/store";
import { STAGE, STAGE_COLOR } from "../utils/stage";
import { AppVersion } from "./AppVersion";
import { checkRouteAuth } from "./BaseLayout.core";
import { Dialog, useDialogStore } from "./Dialog";
import { useDirtyGuardActivation } from "./DirtyGuard";
import UnauthorizedPage from "./error-pages/UnauthorizedPage.vue";
import { GuidedTour, InternalGuidedTour, useGuidedTourStore } from "./GuidedTour";
import { useNetworkGuardActivation } from "./NetworkGuard";
import ProfileMenu from "./ProfileMenu.vue";
import { configForRouteName, useModuleRouting } from "./routing";
import ShortcutsDialog from "./ShortcutsDialog.vue";
import { useSkeletonStore } from "./store";
import { Toast } from "./Toast";

type BaseLayoutProps = {
    /**
     * Only used if your module is not mapped in the module registry.
     */
    customTitle?: string;
    moduleName?: string;
    settingsSections?: Section[];
    noLoginRequired?: boolean;
    featureToggles?: string[];
};

type ExclusiveDropDowns = "routes" | "modules" | "locations" | "countries" | "languages" | "profile";

const props = withDefaults(defineProps<BaseLayoutProps>(), {
    settingsSections: () => [],
    featureToggles: () => [],
});
const { optM } = useMsgFormatter();

const dialogStore = useDialogStore();
const guidedTourStore = useGuidedTourStore();

const openDropDown = ref<ExclusiveDropDowns>();
const toast = ref<InstanceType<typeof WuxToast>>();

const router = useRouter();
const userStore = useUserStore();
const currentModule = useCurrentModule();

const moduleStore = useModulesStore();
const locationStore = useLocationsStore();
const wawiConfigsStore = useWawiConfigsStore();
const featureTogglesStore = useFeatureTogglesStore();
const store = useSkeletonStore();

// prevent showing a 404 page when we don't know the actual status yet or are logging out already.
const dependenciesAreReady = computed(
    () =>
        props.noLoginRequired ||
        (userStore.isLoggedIn &&
            moduleStore.status === ModuleLoadingStatus.DONE &&
            locationStore.status === LocationLoadingStatus.DONE),
);

const hasGenericRoot = computed(() => router.options.routes.some(({ name }) => name === "root"));
const currentRoute = useRoute();
const currentRouteConfig = computed(() => configForRouteName(currentRoute.name, store.routeConfig));
const currentRouteName = computed(() => currentRouteConfig.value?.name);
const currentRouteTitle = computed(() => optM(currentRouteConfig.value?.titleMsg));
const unauthorizedReason = computed(() =>
    checkRouteAuth(currentRouteConfig.value, currentModule.value, wawiConfigsStore, featureTogglesStore, userStore),
);

const { availableRoutes, navigateTo } = useModuleRouting();

const availableModules = useAvailableModules();
const title = computed(
    () =>
        (props.customTitle ||
            currentRouteTitle.value ||
            getModuleFullName(optM, CurrentModule.get()) ||
            props.moduleName ||
            "") + (STAGE ? ` (${STAGE})` : ""),
);
const moduleOptions = computed<{ value: string; label: string }[]>(() =>
    availableModules.value.map(({ name, descriptionMsg }: Module) => ({
        value: name,
        label: optM(descriptionMsg) || "",
        shortLabel: name,
        href: WaWiModule.getModuleUrl({ moduleName: name }),
    })),
);

const onChangeModule = (moduleName: string, newTab?: boolean) => {
    WaWiModule.set({ moduleName, newTab });
};

const currentLocation = WaWiLocation.get();
const needsLocation = store.usesLocations && !currentLocation;
const filteredLocations = useFilteredLocations();

const locationOptions = computed<{ value: string; label: string }[]>(() =>
    filteredLocations.value.map((loc) => ({
        value: loc.id,
        shortLabel: loc.id,
        label: loc.name,
        href: WaWiLocation.getUrl(loc.id),
    })),
);

const currentCountry = WaWiCountry.get();

const countryOptions = getCountryOptions()
    .filter((countryOption) => userStore.userCountries.includes(countryOption.code))
    .map((countryOption) => ({
        value: countryOption.code,
        label: countryOption.name,
        href: WaWiCountry.getUrl(countryOption.code),
    }));

const onChangeLocation = (loc: string) => {
    WaWiLocation.saveLocation(loc);
    WaWiLocation.set(loc);
};

const onChangeCountry = (country: string) => {
    WaWiCountry.change(country);
};

// Override the success function to show a new toast message
Toast.success = (props: WuxToastElementProps) => {
    toast.value?.showSuccess(props);
};

const showTourCompleteToast = () => {
    Toast.success({
        contentMsg: __("ui.libs.skeleton.guided-tour-complete.content"),
    });
};
const completeTour = () => {
    InternalGuidedTour.complete();
    showTourCompleteToast();
};
const skipTour = () => {
    InternalGuidedTour.skipTour();
    showTourCompleteToast();
};

const getAvailableSteps = () => {
    const allSteps = currentRouteConfig.value?.guidedTourSteps ?? [];
    const filteredByPolicy = allSteps.filter(
        ({ requiredPolicies = [] }) => !requiredPolicies.length || userStore.hasPolicy(requiredPolicies),
    );
    // only display visible steps if the isVisisble is provided
    return filteredByPolicy.filter((step) => step.isVisible?.() ?? true);
};

watch(
    () => [currentRouteName.value, featureTogglesStore.featureTogglesStatus, wawiConfigsStore.configsStatus],
    () => {
        // Build the guided tour store
        if (
            // we don't want to init guided tour steps for UI system tests
            !WaWiCountry.isSystemTestCountry() &&
            currentRouteName.value &&
            featureTogglesStore.featureTogglesStatus === LoadingStatus.READY &&
            wawiConfigsStore.configsStatus === LoadingStatus.READY
        ) {
            const steps = getAvailableSteps();
            InternalGuidedTour.prepare(currentRouteName.value, steps);
        }

        if ((window.top as any)?.setPageId) {
            (window.top as any).setPageId(router.currentRoute.value?.name ?? "root");
        }
    },
);

if ((window.top as any)?.setModuleRoutes) {
    (window.top as any).setModuleRoutes(router.getRoutes() ?? []);
}

watchEffect(() => {
    // prefer first a custom title, then the short title
    const routeTitle = router.currentRoute.value.meta?.shortTitle || optM(currentRouteConfig.value?.titleMsg);
    document.title =
        title.value ||
        [routeTitle, CurrentModule.get()?.name || props.moduleName, currentLocation, currentCountry, STAGE]
            .filter(Boolean)
            .join(" - ");
});

const backAction = CurrentModule.get()
    ? computed<{ icon: Icon; onClick: () => void; href: string }>(() =>
          !hasGenericRoot.value || ["root", LOCATION_SELECTOR_NAME].includes(router.currentRoute.value.name as string)
              ? {
                    icon: Icons.chevron_left_small,
                    onClick: () => {
                        WaWiModule.setLaunchpad();
                    },
                    href: WaWiModule.buildModuleUrl({ modulePath: "/module/launchpad" }),
                }
              : {
                    icon: Icons.grid,
                    onClick: () => {
                        router.push({ name: "root" });
                    },
                    href: WaWiModule.getModuleUrl({ moduleName: CurrentModule.get()!.name }),
                },
      )
    : undefined;

const onToggleDropdown = (dropdown: ExclusiveDropDowns, isOpen: boolean) => {
    if (!isOpen) {
        openDropDown.value = undefined;
    } else {
        openDropDown.value = dropdown;
    }
};

const isSupportedLocation = computed(
    () =>
        !(
            (currentRouteConfig.value?.locationType === LocationType.HQ && !WaWiLocation.isHeadquarter()) ||
            (currentRouteConfig.value?.locationType === LocationType.WH && !WaWiLocation.isWarehouse())
        ),
);
watch(
    () => currentRouteConfig.value?.locationType,
    () => {
        // Do not allow access to location specific masks in modules that are active in both HQ and WH.
        if (!isSupportedLocation.value) {
            Dialog.info({
                contentMsg: {
                    id: __(
                        WaWiLocation.isWarehouse()
                            ? "ui.libs.skeleton.LocationTypeMismatch.wh"
                            : "ui.libs.skeleton.LocationTypeMismatch.hq",
                    ),
                    values: {
                        name: currentRouteConfig.value?.name,
                    },
                },
                onClose: () => {
                    backAction?.value.onClick();
                },
            });
            return;
        }
    },
);

const isDirty = useDirtyGuardActivation();
const isOnline = useNetworkGuardActivation();

// display the page loading indicator only on the first guided tour step.
// for other steps, a different loading indicator is displayed on the step card.
const isGuidedTourLoading = computed(() => guidedTourStore.isLoading && guidedTourStore.index === 0);

AppVersion.useWatchAppVersion();
</script>

<template>
    <WuxLayout
        :title="title"
        :isLoading="store.isLoading || isGuidedTourLoading"
        :isDirty="isDirty"
        :backAction="backAction"
        :newVersionAvailable="store.newVersionAvailable"
        :style="{ '--layout-background-override': STAGE_COLOR }"
    >
        <template #top-bar-right>
            <WuxTopBarDropdown
                v-if="!needsLocation"
                :options="availableRoutes.options"
                :categories="availableRoutes.categories"
                :labelMsg="__('ui.libs.skeleton.nav.page')"
                @change="navigateTo"
                @update:open="onToggleDropdown('routes', $event)"
                :open="openDropDown === 'routes'"
                :value="currentRouteName"
                :shortcut="Shortcuts['1'].alt"
            />
            <WuxTopBarDropdown
                v-if="!needsLocation"
                :options="moduleOptions"
                :labelMsg="__('ui.libs.skeleton.nav.module')"
                @change="onChangeModule"
                @update:open="onToggleDropdown('modules', $event)"
                :open="openDropDown === 'modules'"
                :value="CurrentModule.get()?.name"
                :shortcut="Shortcuts['2'].alt"
                flipLabels
            />
            <WuxTopBarDropdown
                v-if="store.usesLocations"
                :options="locationOptions"
                :labelMsg="__('ui.libs.skeleton.nav.location')"
                @change="onChangeLocation"
                @update:open="onToggleDropdown('locations', $event)"
                :open="openDropDown === 'locations'"
                :value="currentLocation"
                :shortcut="Shortcuts['3'].alt"
            />
            <WuxTopBarDropdown
                :options="countryOptions"
                :labelMsg="__('ui.libs.skeleton.nav.country')"
                @change="onChangeCountry"
                @update:open="onToggleDropdown('countries', $event)"
                :open="openDropDown === 'countries'"
                :value="currentCountry"
                :shortcut="Shortcuts['4'].alt"
            />
            <ProfileMenu
                @update:open="onToggleDropdown('profile', $event)"
                :open="openDropDown === 'profile'"
                :shortcut="Shortcuts['5'].alt"
            />
        </template>
        <template #main>
            <UnauthorizedPage v-if="dependenciesAreReady && unauthorizedReason" :reason="unauthorizedReason" />
            <WawiConfigsWrapper
                :settingsSections="props.settingsSections"
                :featureToggles="props.featureToggles"
                v-if="dependenciesAreReady && !unauthorizedReason && isSupportedLocation"
            >
                <slot name="main">
                    <router-view />
                </slot>
            </WawiConfigsWrapper>
            <!-- Dialog has to appear below main content, otherwise the content jumps briefly in the UI. -->
            <WuxDialog v-bind="layer" v-for="layer in dialogStore.layers" :key="layer.id" />
            <ShortcutsDialog v-if="store.showShortcutInfo" />
            <!-- Toasts section -->
            <WuxToast ref="toast" />
            <!-- Intro to the guided tour for the new users -->
            <WuxGuidedTourIntro
                :title="currentRouteTitle"
                v-if="currentRouteTitle && guidedTourStore.showTourIntro"
                @start="GuidedTour.start"
                @skip="skipTour"
            />
            <!-- The guided tour steps -->
            <WuxGuidedTourStep
                v-if="!guidedTourStore.showTourIntro && guidedTourStore.props"
                @complete="completeTour"
                @next="InternalGuidedTour.next"
                @previous="InternalGuidedTour.previous"
                v-bind="guidedTourStore.props"
            />
            <WuxOfflineIndicator :isOffline="!isOnline" />
        </template>
    </WuxLayout>
</template>
