import { computed, ComputedRef, CSSProperties, Directive, DirectiveBinding, reactive, ref, watch } from "vue";
import { EventHandler, Props } from "../../types";
import WuxPopover from "./WuxPopover.vue";

export type PopoverAlignment = "left" | "right";
export type PopoverOpenMechanism = "hover" | "click" | "manual";

export type PopoverTargetProps = {
    openMechanism?: PopoverOpenMechanism;
    alignment?: PopoverAlignment;
};

type WuxPopoverProps = Props<typeof WuxPopover>;

export type UsePopoverReturn = {
    popoverProps: ComputedRef<WuxPopoverProps>;
    vWuxPopoverTarget: Directive<HTMLElement, PopoverTargetProps>;
};

/**
 * layoutObserverMap tracks which elements need to be observed for viewport changes
 */
const layoutObserverMap = reactive(new Map<HTMLElement, () => void>());
const handleScroll: EventHandler<Event> = (event: Event) => {
    if (event.target === document || event.target === window) {
        return layoutObserverMap.forEach((handler) => handler());
    }
    layoutObserverMap.forEach((handler, element) => (event.target as Element).contains?.(element) && handler());
};

watch(layoutObserverMap, () => {
    if (layoutObserverMap.size) {
        // Observe the scrollend event so that we can update popover positions
        // we could use "scroll" event here to, but I am worried about performance issues
        // "capture" is used here because scroll events don't bubble
        document.addEventListener("scrollend", handleScroll, { capture: true });
        window.addEventListener("resize", handleScroll, { capture: true });
    } else {
        document.removeEventListener("scrollend", handleScroll, { capture: true });
        window.removeEventListener("resize", handleScroll, { capture: true });
    }
});

/**
 * Use in conjunction with the `WuxPopover.vue`.
 * The `vWuxPopoverTarget` directive MUST only be used on a single target!
 */
export const usePopover = (): UsePopoverReturn => {
    const isOpen = ref(false);
    const openMechanism = ref<PopoverOpenMechanism>("hover");
    const targetRect = ref<DOMRectReadOnly>();
    const alignment = ref<PopoverAlignment>("left");
    const element = ref<HTMLElement | undefined>();

    const toggle = () => (isOpen.value = !isOpen.value);
    const show = () => (isOpen.value = true);
    const close = () => (isOpen.value = false);

    const updateTargetPosition = () => (targetRect.value = element.value && element.value.getBoundingClientRect());

    const resizeObserver = new ResizeObserver(() => updateTargetPosition());

    watch(
        () => Boolean(element.value && isOpen.value),
        () => {
            if (!element.value) {
                return;
            }
            if (isOpen.value) {
                layoutObserverMap.set(element.value, updateTargetPosition);
                resizeObserver.observe(element.value);
            } else {
                layoutObserverMap.delete(element.value);
                resizeObserver.unobserve(element.value);
            }
        },
    );

    /**
     * Called on directive mounting and when PopoverTargetProps are updated
     */
    const mounted = (el: HTMLElement, binding: DirectiveBinding<PopoverTargetProps>) => {
        element.value = el;
        alignment.value = binding.value?.alignment ?? "left";
        openMechanism.value = binding.value?.openMechanism ?? "hover";
        if (openMechanism.value === "hover") {
            el.addEventListener("mouseenter", show);
            el.addEventListener("mouseleave", close);
        }
        if (openMechanism.value === "click") {
            el.addEventListener("click", toggle);
        }
    };

    const updated = (el: HTMLElement, binding: DirectiveBinding<PopoverTargetProps>) => {
        alignment.value = binding.value?.alignment ?? "left";
        openMechanism.value = binding.value?.openMechanism ?? "hover";

        if (openMechanism.value === "hover") {
            el.removeEventListener("click", toggle);
            el.addEventListener("mouseenter", show);
            el.addEventListener("mouseleave", close);
        } else if (openMechanism.value === "click") {
            el.addEventListener("click", toggle);
            el.removeEventListener("mouseenter", show);
            el.removeEventListener("mouseleave", close);
        } else {
            el.removeEventListener("click", toggle);
            el.removeEventListener("mouseenter", show);
            el.removeEventListener("mouseleave", close);
        }
    };

    return {
        popoverProps: computed<WuxPopoverProps>(() => ({
            isOpen: isOpen.value,
            openMechanism: openMechanism.value,
            targetRect: targetRect.value,
            alignment: alignment.value,
            "onUpdate:isOpen": (isOpenUpdate: boolean) => {
                // Without this if check, rapid clicking on a popover would trigger the `watch` above many times,
                // causing the `ResizeObserver` loop to become overwhelmed
                if (isOpen.value !== isOpenUpdate) isOpen.value = isOpenUpdate;
            },
        })),
        /**
         * this is a vue directive, so it needs to start with a `v`:
         * https://vuejs.org/guide/reusability/custom-directives.html#introduction
         */
        vWuxPopoverTarget: {
            mounted,
            updated,
            beforeUnmount: (el) => {
                el.removeEventListener("click", toggle);
                el.removeEventListener("mouseenter", show);
                el.removeEventListener("mouseleave", close);
                resizeObserver.disconnect();
                layoutObserverMap.delete(el);
            },
        },
    };
};

type PopoverCoords = Pick<CSSProperties, "left" | "top">;
/**
 * Calculates the position of the popover in the [top layer](https://developer.mozilla.org/en-US/docs/Glossary/Top_layer).
 * The absolute position in the top layer is relative to the ViewPort, so we need to account for the scroll positions.
 */
export const calculatePopoverCoords = (
    alignment: PopoverAlignment,
    targetRect: DOMRectReadOnly,
    popoverRect: DOMRectReadOnly,
    offset?: number,
): PopoverCoords => {
    const { scrollTop, clientHeight, scrollLeft } = document.documentElement;
    const { left: leftTarget, right: rightTarget, top: topTarget, bottom: bottomTarget } = targetRect;
    const { height, width } = popoverRect;

    const left = (alignment === "left" ? leftTarget : rightTarget - width) + scrollLeft;

    const defaultOffset = offset ?? 0;
    const topAbove = topTarget - height + scrollTop - defaultOffset;
    const remainingAbove = topTarget - height;
    const topBeneath = bottomTarget + scrollTop + defaultOffset;
    const remainingBeneath = clientHeight - bottomTarget - height;
    const top = remainingBeneath < 0 && remainingAbove > remainingBeneath ? topAbove : topBeneath;

    return Object.fromEntries(Object.entries({ left, top }).map(([k, v]) => [k, `${v}px`]));
};
