<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { __, useMsgFormatter } from "../../i18n";
import { ComponentUtils } from "../../utils";
import { Shortcuts, ShortcutUtil } from "../../utils/shortcuts";
import WuxBottomBar from "../WuxBottomBar/WuxBottomBar.vue";
import WuxButton from "../WuxButton/WuxButton.vue";
import WuxShortcutKeys from "../WuxShortcuts/WuxShortcutKeys.vue";
import { WuxGuidedTourStepProps } from "./WuxGuidedTourStep.core";

type ArrowPosition = {
    className?: string;
    top?: number;
    left?: number;
};

type GuidedTourPosition = {
    left: number;
    top: number;
};

const props = defineProps<WuxGuidedTourStepProps>();

const { m } = useMsgFormatter();

const emit = defineEmits<{
    (e: "next"): void;
    (e: "previous"): void;
    (e: "complete"): void;
}>();

const tour = ref<HTMLDivElement | null>(null);
const pointer = ref<HTMLDivElement | null>(null);
const targetElement = ref<Element | null>(null);
const overlayDark = ref<HTMLDivElement | null>(null);
const overlayLight = ref<HTMLDivElement | null>(null);

const isLastStep = computed(() => props.currentStep >= props.totalSteps);
const isFirstStep = computed(() => props.currentStep === 1);
const isTargetVisible = ref<boolean>(true);

const ArrowRightShortcut = Shortcuts["ArrowRight"];
const ArrowLeftShortcut = Shortcuts["ArrowLeft"];
const EscapeShortcut = Shortcuts["Escape"];

// register the shortcuts for the guided tour step navigation
ShortcutUtil.useGlobal(
    [
        ArrowRightShortcut,
        (e) => {
            if (props.isStepLoading) {
                return;
            }
            e.preventDefault();
            onNext();
        },
    ],
    [
        ArrowLeftShortcut,
        (e) => {
            if (props.isStepLoading) {
                return;
            }
            e.preventDefault();
            onPrevious();
        },
    ],
    [
        EscapeShortcut,
        (e) => {
            if (props.isStepLoading) {
                return;
            }
            e.preventDefault();
            emit("complete");
        },
    ],
);

onMounted(() => {
    // always start the tour at the top of the page
    window.scrollTo({ top: 0, behavior: "instant" });
    calculatePosition();
    // we need to recalculate the position of the guided tour if the screen size change
    addEventListener("resize", ComponentUtils.debounce(calculatePosition, 100));
    // custom class name to manage the layout when the guided tour is running
    document.body.classList.add("body--active-wux-guided-tour");
});

onUnmounted(() => {
    removeEventListener("resize", ComponentUtils.debounce(calculatePosition, 100));
    document.body.classList.remove("body--active-wux-guided-tour");
});

// In case the target element is not found or is outside the viewport, place the tour in the bottom right corner
const setDefaultPosition = () => {
    if (!tour.value) {
        return;
    }
    tour.value.removeAttribute("style");
    tour.value.style.right = "2rem";
    tour.value.style.bottom = "2rem";
    tour.value.style.top = "unset";
    tour.value.style.left = "unset";
    const arrow = pointer.value;
    if (arrow) {
        // Change the class name
        arrow.classList.remove(...arrow.classList);
        arrow.removeAttribute("style");
    }
};
// Change the position of the Guided tour pop-up and the arrow
const setPositions = (tourPosition: GuidedTourPosition, arrowPosition: ArrowPosition) => {
    if (!tour.value) {
        return;
    }
    tour.value.removeAttribute("style");
    tour.value.style.setProperty("--guided-tour-top", `${tourPosition.top}px`);
    tour.value.style.setProperty("--guided-tour-left", `${tourPosition.left}px`);

    // Update the arrow styles
    const arrow = pointer.value;
    if (arrow) {
        // Change the class name
        arrow.classList.remove(...arrow.classList);
        if (arrowPosition.className) {
            arrow.classList.add("wux-guided-tour__arrow");
            arrow.classList.add(arrowPosition.className);
        }

        arrow.removeAttribute("style");
        if (arrowPosition.left) arrow.style.left = `${arrowPosition.left}px`;
        if (arrowPosition.top) arrow.style.top = `${arrowPosition.top}px`;
    }
};

const repaintOverlay = (targetRect: DOMRect) => {
    // set the bright overlay on top of the target element
    if (overlayLight.value) {
        overlayLight.value.style.setProperty("--guided-tour-bright-top", `${targetRect.top}px`);
        overlayLight.value.style.setProperty("--guided-tour-bright-left", `${targetRect.left}px`);
        overlayLight.value.style.setProperty("--guided-tour-bright-width", `${targetRect.width}px`);
        overlayLight.value.style.setProperty("--guided-tour-bright-height", `${targetRect.height}px`);
    }

    // Currenlty the backdrop-filter does not update the height and width immediatly
    // This fix will force the browser to repaint it correctly
    overlayLight.value?.style.removeProperty("backdrop-filter");
    overlayDark.value?.style.removeProperty("backdrop-filter");
};

// cover possible cases when the element is not visible: heigh or width is 0, display none, visibility hidden, z-index < 0
const isTargetElementVisible = (element: HTMLElement, checkViewport = true): boolean => {
    if (!element) {
        return false;
    }

    const isInViewport = (): boolean => {
        const rect = element.getBoundingClientRect();
        // Make sure the center of the component is in the view port. Most of the times the user sees at
        // least 25% of the component
        return (
            rect.top + rect.height / 2 >= 0 &&
            rect.left + rect.width / 2 >= 0 &&
            rect.bottom - rect.height / 2 <= window.innerHeight &&
            rect.right - rect.width / 2 <= window.innerWidth
        );
    };

    // Only needed for the target element, and not the parent elements
    if (checkViewport && !isInViewport()) {
        return false;
    }

    const computedStyle = window.getComputedStyle(element);
    const hasZeroHeight = computedStyle.height === "0px";
    const hasZeroWidth = computedStyle.width === "0px";
    const isHidden = computedStyle.display === "none" || computedStyle.visibility === "hidden";
    const hasLowerZIndex = computedStyle.zIndex !== "auto" && Number(computedStyle.zIndex) < 0;

    // as some components have complex structures, certain elements within these components are intentionally excluded from checks
    if (
        // sidebar elements
        element.getAttribute("data-tour-id") === "wux-sidebar-tab-left" ||
        element.getAttribute("data-tour-id") === "wux-sidebar-tab-right" ||
        element.classList.contains("wux-sidebars") ||
        element.classList.contains("wux-sidebars__sidebar-tab-container")
    ) {
        return true;
    }

    if (hasZeroHeight || hasZeroWidth || isHidden || hasLowerZIndex) {
        return false;
    }

    const parentElement = element.parentElement;

    if (!parentElement) {
        return true;
    }

    return isTargetElementVisible(parentElement, false);
};

// if target element is too big, show the guided tour card in the default position
const isTargetElementTooBig = (element: HTMLElement): boolean => {
    if (!element || !tour.value) {
        return false;
    }

    const popUpCardRect = tour.value.getBoundingClientRect();
    const overlayLightRect = element.getBoundingClientRect();

    // calculate if enough space to show the card vertically
    const offset = 20; // to handle a little extra space for the arrow
    const distanceToTop = overlayLightRect.top;
    const distanceToBottom = window.innerHeight - overlayLightRect.bottom;
    const notEnoughSpaceVertically =
        distanceToTop < popUpCardRect.height + offset && distanceToBottom < popUpCardRect.height + offset;

    const notEnoughSpaceHorizontally = overlayLightRect.width + popUpCardRect.width + offset > window.innerWidth;

    return notEnoughSpaceVertically && notEnoughSpaceHorizontally;
};

// TODO: The element might be outside of the viewport => move the focus to the target element
const calculatePosition = async () => {
    // The dimensions of the pop-up guided tour are changing when the content change
    // this will wait for the browser repaint before updating the position of the card
    window.requestAnimationFrame(() => {
        if (!tour.value || !overlayDark.value || !overlayLight.value) {
            return;
        }

        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        const selector = props.selector || `[data-tour-id='${props.tourId}']`;
        targetElement.value = document.querySelector(selector);
        isTargetVisible.value = isTargetElementVisible(targetElement.value as HTMLElement);
        overlayLight.value.style.backdropFilter = "unset";

        // Get the dimensions of the guided tour and the target element

        const popUpCardRect = tour.value.getBoundingClientRect();

        // extra space for the the arrow (size 10) 10 more extra space
        const extraSpace = 10 + 10;
        const tourHeight = popUpCardRect.height + extraSpace;
        const tourWidth = popUpCardRect.width + extraSpace;

        // if the target element is not in the document then place the pop-up in the top left
        if (!targetElement.value) {
            // Hide the bright layer from the dom so it doesn't intersect with the tour pop-up card
            overlayLight.value.style.display = "none";
            setDefaultPosition();
            return;
        }

        overlayLight.value.style.removeProperty("display");

        const targetRect = targetElement.value.getBoundingClientRect();

        // if the element is outside the viewport - Fallback to the bottom of the screen
        if (!isTargetVisible.value) {
            setDefaultPosition();
            return;
        }

        // if the element is too big, show the card in default position
        if (isTargetElementTooBig(targetElement.value as HTMLElement)) {
            setDefaultPosition();
            repaintOverlay(targetRect);
            return;
        }

        overlayDark.value.style.backdropFilter = "unset";

        // the middle of the guided tour and the target element will be aligned
        // the horizontal middle of the target element is targetRect.left + targetRect.width / 2
        // to align the tour to this middle we shift the tour tourWidth / 2 to the left
        // the arrow will be always on the middle of the target element (add 10 for spacing)
        const horizontalMiddleElement = targetRect.left + targetRect.width / 2 - tourWidth / 2;
        const horizontalLeft = Math.min(Math.max(horizontalMiddleElement, 0), viewportWidth - tourWidth);
        const horizontalArrow = targetRect.left + targetRect.width / 2 - horizontalLeft - 10;

        // Same logic but vertically
        const verticalMiddleElement = targetRect.top + targetRect.height / 2 - tourHeight / 2;
        const verticalTop = Math.min(Math.max(verticalMiddleElement, 0), viewportHeight - tourHeight);
        const verticalArrow = targetRect.top + targetRect.height / 2 - verticalTop - 10;

        // Check if pop up card can be placed below the target element
        if (targetRect.bottom + tourHeight <= viewportHeight) {
            setPositions(
                {
                    top: targetRect.bottom + extraSpace,
                    left: horizontalLeft,
                },
                {
                    className: "wux-guided-tour__arrow--up",
                    top: undefined,
                    left: horizontalArrow,
                },
            );
        } // Check if pop up card can be placed above the target element
        else if (targetRect.top - tourHeight >= 0) {
            setPositions(
                {
                    top: targetRect.top - tourHeight,
                    left: horizontalLeft,
                },
                {
                    className: "wux-guided-tour__arrow--down",
                    top: undefined,
                    left: horizontalArrow,
                },
            );
        } // Check if pop up card can be placed to the right of the target element
        else if (targetRect.right + tourWidth <= viewportWidth) {
            setPositions(
                {
                    top: verticalTop,
                    left: targetRect.right + extraSpace,
                },
                {
                    className: "wux-guided-tour__arrow--left",
                    top: verticalArrow,
                    left: undefined,
                },
            );
        }
        // Check if pop up card can be placed to the left of the target element
        else if (targetRect.left - tourWidth >= 0) {
            setPositions(
                {
                    top: verticalTop,
                    left: targetRect.left - tourWidth,
                },
                {
                    className: "wux-guided-tour__arrow--right",
                    top: verticalArrow,
                    left: undefined,
                },
            );
        }
        repaintOverlay(targetRect);
    });
};

watch(
    () => [props.tourId, props.selector],
    () => calculatePosition(),
);

const onNext = () => (isLastStep.value ? emit("complete") : emit("next"));
const onPrevious = () => (isFirstStep.value ? undefined : emit("previous"));
const nextLabel = computed(() =>
    isLastStep.value
        ? __("ui.components.guided-tour.complete")
        : {
              id: __("ui.components.guided-tour.continue"),
              values: {
                  step: props.currentStep,
                  total: props.totalSteps,
              },
          },
);
const currentStepOfTotalSteps = computed<string>(() => `${props.currentStep}/${props.totalSteps}`);
</script>

<template>
    <Teleport to="body">
        <div ref="overlayDark" class="wux-guided-tour__dark-layer" />
        <div ref="overlayLight" class="wux-guided-tour__bright-layer" />
        <div ref="tour" class="wux-guided-tour hidden-print">
            <div ref="pointer"></div>
            <div class="wux-guided-tour-card">
                <div class="wux-guided-tour-card__header">{{ m(props.titleMsg) }}</div>
                <div v-if="!isTargetVisible" class="wux-guided-tour-card__not-visible">
                    {{ m("ui.components.guided-tour.not-visible") }}
                </div>
                <div class="wux-guided-tour-card__content">{{ m(props.contentMsg) }}</div>
                <WuxBottomBar isComponentLevel>
                    <div v-if="props.isStepLoading" class="wux-guided-tour-card__loader" />
                    <WuxButton
                        type="button"
                        variant="text"
                        @click="emit('complete')"
                        :labelMsg="__('ui.components.guided-tour.cancel')"
                        :isDisabled="props.isStepLoading"
                    />
                    <WuxButton
                        type="button"
                        variant="text"
                        @click="emit('previous')"
                        v-if="props.currentStep > 1"
                        :labelMsg="__('ui.components.guided-tour.previous')"
                        :isDisabled="props.isStepLoading"
                    />
                    <WuxButton
                        type="button"
                        isPrimary
                        variant="text"
                        @click="onNext"
                        :labelMsg="nextLabel"
                        :isDisabled="props.isStepLoading"
                    />
                    <div
                        class="wux-guided-tour-card__shortcuts"
                        :class="props.isStepLoading ? `wux-guided-tour-card__shortcuts--disabled` : ''"
                    >
                        <div>{{ currentStepOfTotalSteps }}</div>
                        <div class="wux-guided-tour-card__shortcuts__keys-wrapper">
                            <span class="wux-text-label">{{ m("ui.components.guided-tour.shortcut-navigation") }}</span>
                            <div class="wux-guided-tour-card__shortcuts__keys">
                                <WuxShortcutKeys :shortcut="EscapeShortcut" inline />
                                <WuxShortcutKeys v-if="props.currentStep > 1" :shortcut="ArrowLeftShortcut" inline />
                                <WuxShortcutKeys :shortcut="ArrowRightShortcut" inline />
                            </div>
                        </div>
                    </div>
                </WuxBottomBar>
            </div>
        </div>
    </Teleport>
</template>

<style lang="scss">
@use "../../../assets/styles/variables.scss";

// manage the layout
body {
    &.body--active-wux-guided-tour {
        pointer-events: none;
        overflow: hidden;
    }
}

.wux-guided-tour {
    $arrowSize: 8px;

    position: absolute;
    background-color: white;
    margin: 0.2rem;
    z-index: variables.z("guided-tour");
    max-width: var(--guided-tour-max-width, 416px); // the maximum width of the guided tour card according to design
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
    top: var(--guided-tour-top, 0);
    left: var(--guided-tour-left, 0);
    pointer-events: auto;

    &__arrow {
        position: absolute;
        content: "";
        border: solid $arrowSize transparent;
        border-color: var(--wux-color-white);

        &--down {
            bottom: -$arrowSize;
            margin-left: calc($arrowSize / 2 * -1);
            transform: rotate(45deg);
        }

        &--up {
            top: -$arrowSize;
            margin-left: calc($arrowSize / 2 * -1);
            transform: rotate(45deg);
        }

        &--right {
            right: -$arrowSize;
            margin-top: calc($arrowSize / 2 * -1);
            transform: rotate(45deg);
        }

        &--left {
            left: -$arrowSize;
            margin-top: calc($arrowSize / 2 * -1);
            transform: rotate(45deg);
        }
    }

    &__bright-layer {
        position: absolute;
        z-index: variables.z("guided-tour");
        top: var(--guided-tour-bright-top);
        left: var(--guided-tour-bright-left);
        width: var(--guided-tour-bright-width);
        height: var(--guided-tour-bright-height);
        backdrop-filter: brightness(200%);
    }

    &__dark-layer {
        position: fixed;
        z-index: variables.z("guided-tour");
        top: 0;
        left: 0;
        width: 100dvw;
        height: 100dvh;
        backdrop-filter: brightness(50%);
    }
}

.wux-guided-tour-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 20rem;
    max-width: 50dvw;

    &__header {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.6rem;
        padding: 1rem 1rem 0rem 1rem;
    }
    &__content,
    &__not-visible {
        padding: 0 1rem 0.5rem 1rem;
    }
    &__not-visible {
        color: var(--wawi-color-red-500);
    }
    &__loader {
        position: absolute;
        left: 0;
        top: 0px;
        width: 100%;
        height: 1px;
        overflow: hidden;
        &:after {
            display: block;
            content: " ";
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--wux-color-info);
            left: 0;
            transform: translate3d(-150%, 0, 0);
            animation: step-loader-stripe 1s infinite linear;
        }
    }

    &__shortcuts {
        display: none;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        padding: 0 1rem;
        width: 100%;
        &--disabled {
            opacity: 0.5;
        }
        &__keys-wrapper {
            display: flex;
            gap: 0.25rem;
            align-items: center;
        }
        .wux-text-label {
            margin-right: 0.5rem;
        }
        &__keys {
            display: flex;
            gap: 0.25rem;
            align-items: center;
            .wux-shortcut-keys {
                kbd {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 1.5rem;
                    min-width: 1.5rem;
                    box-sizing: border-box;
                    text-align: center;
                    font-size: 0.825rem;
                    padding: 0 0.25rem;
                }
            }
        }
    }

    @keyframes step-loader-stripe {
        0% {
            transform: translate3d(-150%, 0, 0);
        }
        100% {
            transform: translate3d(100%, 0, 0);
        }
    }
}

// Custom adjustments are made for popover elements (like dialog). These adjustments are necessary to ensure the guided tour functions as expected.
// TODO: In the future, consider moving the guided tour functionality to the Popover or Dialog API. Alternatively, consider update the guided tour by using shortcuts navigation only.

main {
    &:has(.wux-guided-tour) {
        dialog.wux-dialog[open] {
            &::backdrop {
                opacity: 0;
            }
        }
        &:has(dialog.wux-dialog[open]) {
            // hide the buttons and show the shortcuts hint instead
            .wux-guided-tour-card .wux-bottom-bar .wux-button {
                display: none;
            }
            .wux-guided-tour-card__shortcuts {
                display: flex;
            }
            .wux-guided-tour-card .wux-bottom-bar > .actions {
                flex-direction: row;
            }
        }
    }
}
</style>
