<script lang="ts" setup>
import { computed } from "vue";
import { useMsgFormatter } from "../../i18n";
import { Icon, Icons } from "../../icons";
import WuxIcon from "../WuxIcon/WuxIcon.vue";
import { WuxTileProps, WuxTileStatus } from "./WuxTile.core";

const iconStatusMap: Record<WuxTileStatus, { src: Icon; color?: string }> = {
    [WuxTileStatus.SUCCESS]: { src: Icons.circle_check, color: "var(--wux-color-success)" },
    [WuxTileStatus.WARNING]: { src: Icons.triangle_warning, color: "var(--wux-color-warning)" },
    [WuxTileStatus.INFO]: { src: Icons.circle_information, color: "var(--wux-color-info)" },
    [WuxTileStatus.ERROR]: { src: Icons.circle_x, color: "var(--wux-color-error)" },
};

const icon = computed<{ src: Icon; color?: string } | undefined>(() =>
    props.status ? iconStatusMap[props.status] : undefined,
);

const classNameStatus = computed<string>(() => (props.status ? "wux-tile--" + props.status : ""));

const props = defineProps<WuxTileProps>();
const { optM } = useMsgFormatter();
</script>

<template>
    <section class="wux-tile" :class="[classNameStatus, { 'wux-tile--arrow': showArrow }]">
        <div v-if="props.titleMsg" class="wux-tile__header">
            <WuxIcon v-if="icon" :src="icon.src" :size="48" :color="icon.color" />
            <h2>{{ optM(props.titleMsg) }}</h2>
        </div>
        <div class="wux-tile__content">
            <slot />
        </div>
        <!-- loading indicator -->
        <div v-if="props.isLoading" class="wux-tile__loading" />
    </section>
</template>

<style lang="scss">
@use "../../../assets/styles/mixins.scss";
.wux-tile {
    --wux-tile-padding: 1rem;
    --wux-tile-content-height: auto;
    border-radius: var(--wux-tile-border-radius, 0);
    position: relative;
    break-inside: avoid-column;
    background-color: var(--wux-color-white);
    box-shadow:
        rgba(0, 0, 0, 0.1) 1px 1px 4px 1px,
        var(--wux-color-body-bg) 0 0 0 2px;
    border: 1px solid transparent;
    &--warning {
        border-color: var(--wux-color-warning);
    }
    &--error {
        border-color: var(--wux-color-error);
    }
    &--info {
        border-color: var(--wux-color-info);
    }
    &--success {
        border-color: var(--wux-color-success);
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: var(--wux-tile-padding);
        padding-bottom: 0;
    }

    &__content {
        box-sizing: border-box;
        padding: var(--wux-tile-padding);
        height: var(--wux-tile-content-height);
    }

    &--arrow:after {
        position: absolute;
        content: "";
        bottom: -8px;
        left: calc(50% - 8px);
        transform: rotate(45deg);
        border-width: 8px;
        border-style: solid;
        border-top-color: transparent;
        border-left-color: transparent;
        border-image: initial;
        border-right-color: var(--wux-color-white);
        border-bottom-color: var(--wux-color-white);
        box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 3px 0px;
    }

    // loader
    &__loading {
        @include mixins.topLoadingBar();
    }

    &:has(.wux-bottom-bar) {
        --wux-bottom-bar-position: absolute;
        z-index: 0; // This is important to override the bottom bar z-index inside the tile
        .wux-tile__content {
            padding-bottom: calc(var(--wux-bottom-bar-height) + var(--wux-tile-padding));
        }
    }
}
</style>
