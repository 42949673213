<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useMsgFormatter } from "../../i18n";
import { Icons } from "../../icons";
import { ComponentUtils } from "../../utils";
import { Shortcuts, ShortcutUtil } from "../../utils/shortcuts";
import WuxIcon from "../WuxIcon/WuxIcon.vue";
import WuxShortcutKeys from "../WuxShortcuts/WuxShortcutKeys.vue";
import WuxSpinner from "../WuxSpinner/WuxSpinner.vue";
import { TreeListItem, WuxTreeListProps } from "./WuxTreeList.core";

const props = defineProps<WuxTreeListProps>();

const activeChild = ref<TreeListItem | null>(null);
const activeElements = computed(() => activeChild.value?.items ?? props.items ?? []);

const backButtonShortcut = Shortcuts.Backspace.alt;

let unregisters: (() => void)[] = [];
const { optM, rawM } = useMsgFormatter();

const registerShortcuts = (shortcutsToBeRegistered: TreeListItem[]) => {
    shortcutsToBeRegistered.forEach((item) => {
        unregisters.push(
            ShortcutUtil.registerGlobal(item.shortcut!, (event) => {
                item.shortcut === backButtonShortcut ? onGoBack() : onClick(event, item);
            }),
        );
    });
};

const unregisterShortcuts = () => {
    unregisters.forEach((unregister) => unregister());
    unregisters = [];
};

const whichClick = ref<"click" | "back">(); //used to decide which animation to show

//If item is undefined we are closing the TreeList (i.e., we clicked on an element with no children or we clicked outside the flyout)
const onClick = (e?: MouseEvent | KeyboardEvent, item?: TreeListItem) => {
    whichClick.value = "click";
    if (!item) {
        focusedElement.value = -1;
        activeChild.value = null;
        return;
    }
    if (item.isLoading || item.disabledMsg) return;
    if (!item.items) {
        focusedElement.value = -1;
        pastElements.value = [];
        activeChild.value = null;
        e && item.onClick?.(e);
        return;
    }
    focusedElement.value = 0;
    pastElements.value.push(activeChild.value);
    activeChild.value = item;
};

const onGoBack = () => {
    const previousElement = activeChild.value;
    if (!previousElement) return;
    whichClick.value = "back";
    const pastElement = pastElements.value.pop();
    activeChild.value = pastElement ?? null;
    focusedElement.value =
        activeElements.value.indexOf(
            activeElements.value.filter(
                (elem) => rawM(elem.labelMsg, elem.label) === rawM(previousElement.labelMsg, previousElement.label),
            )[0],
        ) + (activeChild.value ? 1 : 0); // If activeChild is present, the first element (i.e., the element 0) is the header row
};

const focusedElement = ref<number>(-1);
const pastElements = ref<(TreeListItem | null)[]>([]);
const treeItemsRef = ref<Record<string, TreeListItem | undefined>>({}); //needed like this because it's not guaranteed that the ref order will be stable
const firstOptionRef = ref();

const onKeydown = (event: KeyboardEvent) => {
    if (!props.items.length) return;

    const items: TreeListItem[] =
        activeChild.value !== null
            ? [
                  // Placeholder to represent the first row (the category name and the back button/shortcut) when we are in a nested menu
                  {
                      disabledMsg: undefined,
                      items: undefined,
                      label: "firstItem",
                      labelMsg: undefined,
                      onClick: undefined,
                  },
                  ...activeElements.value,
              ]
            : activeElements.value;

    const onArrowDown = (e: KeyboardEvent) => {
        if (!items.some((e) => !e.disabledMsg && !e.isLoading)) return;
        e.preventDefault();

        let nextElementIndex = focusedElement.value !== -1 ? (focusedElement.value + 1) % items.length : 0;

        while (items[nextElementIndex].disabledMsg || items[nextElementIndex].isLoading) {
            nextElementIndex = (nextElementIndex + 1) % items.length;
        }
        focusedElement.value = nextElementIndex;
    };

    const onArrowUp = (e: KeyboardEvent) => {
        if (!items.some((e) => !e.disabledMsg && !e.isLoading)) return;
        e.preventDefault();

        let prevElementIndex =
            focusedElement.value !== -1 && focusedElement.value !== 0 ? focusedElement.value - 1 : items.length - 1;
        while (items[prevElementIndex].disabledMsg || items[prevElementIndex].isLoading) {
            prevElementIndex = prevElementIndex === 0 ? items.length - 1 : prevElementIndex - 1;
        }
        focusedElement.value = prevElementIndex;
    };

    const onSelect = (e: KeyboardEvent) => {
        if (focusedElement.value === -1) return;
        e.preventDefault();

        if (activeChild.value !== null && focusedElement.value === 0) return onGoBack();

        onClick(e, items[focusedElement.value]);
    };

    const onClose = () => {
        onClick();
    };

    return ShortcutUtil.getKeyDownHandler(
        [Shortcuts.ArrowDown, onArrowDown],
        [Shortcuts.ArrowUp, onArrowUp],
        [Shortcuts.Space, onSelect],
        [Shortcuts.Enter, onSelect],
        [Shortcuts.Tab, onClose],
        [Shortcuts.Escape, onClose],
    )(event);
};

const isFocused = (index: number) =>
    index === (activeChild.value !== null ? focusedElement.value - 1 : focusedElement.value);

const onClose = () => {
    onClick();
};

const scrollIntoFocusedItem = () => {
    if (focusedElement.value === -1) return;
    let elem;
    if (activeChild.value === null) {
        const activeElement = activeElements.value?.[focusedElement.value];
        const label = activeElement ? (rawM(activeElement.labelMsg, activeElement.label) ?? "") : "";
        elem = treeItemsRef.value[label];
    } else {
        const index = focusedElement.value - 1;
        if (index === -1) return;
        const activeElement = activeElements.value?.[index];
        const label = activeElement ? (rawM(activeElement.labelMsg, activeElement.label) ?? "") : "";
        elem = focusedElement.value === 0 ? firstOptionRef.value : treeItemsRef.value[label];
    }
    elem?.scrollIntoView({ block: "nearest" });
};

watch(
    () => focusedElement.value,
    () => scrollIntoFocusedItem(),
    { immediate: true },
);

watch(
    () => activeChild.value,
    async () => {
        await ComponentUtils.sleep(0); //needed to wait for the animation to end
        scrollIntoFocusedItem();
    },
    { immediate: true },
);

watch(
    [() => activeChild.value, () => props.hasShortcutsEnabled],
    () => {
        unregisterShortcuts();
        if (!props.hasShortcutsEnabled) return;
        const elementsWithShortcuts = [
            activeChild.value && {
                shortcut: backButtonShortcut,
                label: "back",
            },
            ...(activeElements.value ?? []),
        ]
            .filter(Boolean) // to remove null if activeChild.value === null
            .filter((i) => i?.shortcut);
        registerShortcuts(elementsWithShortcuts);
    },
    { immediate: true },
);

const getKey = (item: TreeListItem) =>
    `${rawM(item.labelMsg, item.label) ?? ""}${rawM(item.descriptionMsg, item.description) ?? ""}${item.items?.length}`;

defineExpose({ onKeydown, onClose });
</script>
<template>
    <div class="wux-tree-list" @close="onClose" tabindex="-1">
        <div class="wux-tree-items">
            <Transition :name="`wux-tree-items__transition--${whichClick}`">
                <div v-if="activeChild" class="wux-tree-items-header" @click="onGoBack" :key="getKey(activeChild)">
                    <div
                        ref="firstOptionRef"
                        class="wux-tree-items__item-list-name"
                        :class="focusedElement === 0 && 'wux-tree-items__item--focused'"
                    >
                        <WuxIcon class="wux-tree-items__item-list-name-icon" :src="Icons.chevron_left_small" />
                        <span class="wux-tree-items__item-list-name-label">
                            {{ rawM(activeChild.labelMsg, activeChild.label) }}
                            <span class="wux-tree-items__item-list-name-description">
                                {{ rawM(activeChild.descriptionMsg, activeChild.description) }}
                            </span>
                        </span>
                        <WuxShortcutKeys
                            v-if="props.hasShortcutsEnabled"
                            class="wux-tree-items__item-shortcut"
                            :shortcut="backButtonShortcut"
                            inline
                        />
                    </div>
                </div>
            </Transition>
            <TransitionGroup :name="`wux-tree-items__transition--${whichClick}`">
                <div
                    v-for="(item, index) in activeElements"
                    :ref="(el: any) => (treeItemsRef[rawM(item.labelMsg, item.label)!] = el)"
                    :key="getKey(item)"
                    class="wux-tree-items__item"
                    :class="[
                        isFocused(index) && 'wux-tree-items__item--focused',
                        item.isSelected && 'wux-tree-items__item--selected',
                        item.disabledMsg && 'wux-tree-items__item--disabled',
                        item.isLoading && 'wux-tree-items__item--loading',
                        item.items && 'wux-tree-items__item--nested',
                    ]"
                    :title="optM(item.disabledMsg)"
                    @click="(e) => onClick(e, item)"
                >
                    <WuxIcon v-if="item.icon" :src="item.icon" />
                    <span class="wux-tree-items__item-label">
                        {{ rawM(item.labelMsg, item.label) }}
                        <span class="wux-tree-items__item-description">
                            {{ rawM(item.descriptionMsg, item.description) }}
                        </span>
                    </span>
                    <WuxSpinner class="wux-tree-items__item-spinner" v-if="item.isLoading" type="component" />
                    <div
                        v-if="!item.items || (item.shortcut && props.hasShortcutsEnabled)"
                        class="wux-tree-items__item-shortcut-wrapper"
                    >
                        <WuxShortcutKeys
                            v-if="item.shortcut && props.hasShortcutsEnabled"
                            class="wux-tree-items__item-shortcut"
                            :shortcut="item.shortcut"
                            inline
                        />
                    </div>
                    <WuxIcon v-if="item.items" :src="Icons.chevron_right_small" />
                </div>
            </TransitionGroup>
        </div>
    </div>
</template>
<style lang="scss">
@use "../../../assets/styles/mixins.scss";

.wux-tree-list {
    min-width: 100%;
    max-width: 40rem;
    &:focus {
        outline: none;
    }
}
.wux-tree-items {
    overflow: hidden;
    --transition-time: 0.1s;

    &__item {
        gap: 0.625rem;
        padding: 9px 10px; // 9px is used to match the style of the scu component and to display, with the maximum height, 7 entries (otherwise, the last would be only partially shown)
        position: relative;
        display: flex;
        overflow-wrap: anywhere;
        @include mixins.hoverAndSelectedStates();
        &:hover:not(.wux-tree-items__item--disabled) {
            cursor: pointer;
            // hack: duplicated class selector for higher specificity
            .wux-tree-items__item-shortcut.wux-tree-items__item-shortcut * {
                color: var(--wux-color-white);
            }
        }
        &-label {
            flex: 1;
            user-select: none;
        }
        &-description {
            display: block;
            color: var(--wux-color-muted);
            font-size: 13px;
        }
        &-shortcut {
            font-size: 16px;
            // added to align vertically the items that don't have a shortcut or nested items
            &-wrapper {
                display: flex;
                justify-content: center;
                align-self: flex-start;
                margin-top: 0.175rem;
                width: 1.5rem;
            }
        }
        &--nested {
            padding-right: 7px; //to align the chevrons to the shortcut keys
        }
        &--focused {
            background-color: var(--wux-color-hover-bg);
        }
        &--loading {
            background-color: var(--wux-color-disabled-bg);
            .wux-tree-items__item-spinner {
                backdrop-filter: none;
            }
        }
    }
    &-header {
        display: flex;
        flex-direction: column;
    }
    &__item-list-name {
        padding: 9px 10px;
        gap: 0.625rem;
        display: flex;
        &-label {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1;
            user-select: none;
        }
        &:hover {
            background-color: var(--wux-color-hover-bg);
            cursor: pointer;
        }
    }
    &__transition {
        &--click-enter-active,
        &--back-enter-active {
            transition: all var(--transition-time) ease-in-out;
            pointer-events: none;
        }

        &--click-leave-active,
        &--back-leave-active {
            display: none;
            pointer-events: none;
        }

        &--click-enter-from {
            transform: translateX(100%);
        }
        &--click-enter-to {
            transform: translateX(0%);
        }

        &--back-enter-from {
            transform: translateX(-100%);
        }
        &--back-enter-to {
            transform: translateX(0%);
        }
    }
}
</style>
