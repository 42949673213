<script lang="ts" setup>
import { MessageOrId, useMsgFormatter } from "../../i18n";
import { Icon } from "../../icons";
import WuxIcon from "../WuxIcon/WuxIcon.vue";

type Props = { icon: Icon; labelMsg?: MessageOrId; label?: string };
const props = defineProps<Props>();

const emit = defineEmits<{ (e: "click", metaOrCtrl: boolean): void }>();

const { rawM } = useMsgFormatter();

const onClick = (e: MouseEvent) => {
    emit("click", e.metaKey || e.ctrlKey);
};
</script>

<template>
    <div role="button" class="wux-profile-menu-item" @click="onClick">
        <WuxIcon :src="props.icon" :size="20" />
        <div>
            <div>
                {{ rawM(props.labelMsg, props.label) }}
            </div>
            <slot />
        </div>
    </div>
</template>

<style lang="scss" scoped>
@use "../../../assets/styles/mixins.scss";

.wux-profile-menu-item {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    gap: 0.5rem;
    padding: 0.5rem;
    color: var(--wux-color-text);
    @include mixins.hoverAndSelectedStates();
    .wux-icon {
        margin-top: 1px;
        color: var(--wux-color-text);
    }
}
</style>
