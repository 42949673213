<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { MessageOrId, useMsgFormatter } from "../../i18n";
import { Icons } from "../../icons";
import { type RawOrMsg } from "../../types";
import { ClickUtil } from "../../utils/click";
import { Shortcut, Shortcuts, ShortcutUtil } from "../../utils/shortcuts";
import WuxButton from "../WuxButton/WuxButton.vue";
import { getGroupedBy } from "../WuxLayout/layout.core";
import { PopoverTargetProps, usePopover } from "../WuxPopover/WuxPopover.core";
import WuxPopover from "../WuxPopover/WuxPopover.vue";
import type { TreeListItem } from "../WuxTreeList/WuxTreeList.core";
import WuxTreeList from "../WuxTreeList/WuxTreeList.vue";

// choose raw string "label" xor translated label using "labelMsg"
type TopBarDropdownOption = {
    value: string;
    categoryId?: string;
    shortLabel?: string;
    shortcut?: Shortcut;
    href?: string;
    disabledMsg?: MessageOrId;
} & RawOrMsg<"label">;

type TopBarCategory = { id: string; labelMsg: MessageOrId; shortcut?: Shortcut; categories?: TopBarCategory[] };

const props = defineProps<{
    /**
     * Will be displayed when no value is specified.
     */
    labelMsg: MessageOrId;
    options: TopBarDropdownOption[];
    categories?: TopBarCategory[];
    value?: string;
    isDisabled?: boolean;
    open?: boolean;
    flipLabels?: boolean;
    /**
     * Needs to be defined initially, can not be changed afterwards.
     */
    shortcut?: Shortcut;
}>();

const emit = defineEmits<{
    /**
     * `string`
     */
    (e: "change", value: string, metaKey: boolean): void;
    (e: "update:open", isOpen: boolean): void;
}>();

const { optM, rawM } = useMsgFormatter();

const { vWuxPopoverTarget, popoverProps } = usePopover();

const popProps: PopoverTargetProps = {
    openMechanism: "manual",
};

const buttonRef = ref<InstanceType<typeof WuxButton>>();
const popoverRef = ref<HTMLDivElement | null>(null);

onMounted(() => {
    props.shortcut &&
        ShortcutUtil.registerGlobal(props.shortcut, (e) => {
            e.preventDefault();
            buttonRef.value?.focus(); // to make the keyboard navigation work
            onToggle();
        });
    // close the top bar dropdown if click outside the top bar dropdown options
    if (popoverRef.value) {
        ClickUtil.registerOutsideClick([popoverRef.value], () => {
            // ... but only if it is actually open
            if (props.open) {
                onToggled();
            }
        });
    }
});

const currentOption = computed(() => props.options.find(({ value }) => value === props.value));
const currentPopoverLabel = computed(
    () =>
        currentOption.value?.shortLabel ||
        rawM(currentOption.value?.labelMsg, currentOption.value?.label) ||
        optM(props.labelMsg),
);

// if there is currently nothing selected, but there is an option to select, than we allow to toggle the menu
// if the only available option is selected, then we don't allow to toggle the menu
const hasOptionsToChoose = computed(() => props.options.length > (currentOption.value ? 1 : 0));
const canBeToggled = computed(() => hasOptionsToChoose.value && !props.isDisabled);

const onToggle = () => {
    if (canBeToggled.value) {
        emit("update:open", !props.open);
    }
};
// when the user clicks outside the fly out, we need to update the internal toggle state
const onToggled = async () => {
    if (props.open) {
        onToggle();
    }
};

const onTriggerHandler = (value: string) => (e: MouseEvent | KeyboardEvent) => {
    // Every item also has an href property, to be able to open the link with "right-click/open in another tab".
    // We need to prevent opening that link on mouse click to use the vue router instead of opening a new page.
    e.preventDefault();
    emit("update:open", false);
    emit("change", value, e.metaKey || e.ctrlKey);
};

const flip = (labels: string[]) => (props.flipLabels ? Array.from(labels).reverse() : labels);

const getNestedItems = (category: TopBarCategory, grouped: [string, TreeListItem[]][]): TreeListItem | undefined => {
    const allNestedItems =
        category.categories?.map((nestedCategory) => getNestedItems(nestedCategory, grouped)).filter(Boolean) ?? [];

    const items = grouped.find((item) => category.id === item[0])?.[1];

    const allItems = [...(items ?? []), ...allNestedItems];

    return items
        ? {
              items: allItems,
              shortcut: category.shortcut,
              label: optM(category.labelMsg) ?? "",
              isSelected: props.value
                  ? allItems.some(
                        (item) =>
                            item.isSelected || (rawM(item.labelMsg, item.label) || "").includes(props.value ?? ""),
                    )
                  : false,
          }
        : undefined;
};

const items = computed(() => {
    const { notGrouped, grouped } = getGroupedBy(
        "categoryId",
        props.options.map(({ labelMsg, label, shortLabel, value, categoryId, shortcut, href, disabledMsg }) => ({
            shortcut,
            categoryId,
            label: flip([rawM(labelMsg, label), shortLabel].filter(Boolean))[0],
            description: flip([rawM(labelMsg, label), shortLabel].filter(Boolean))[1],
            onClick: (event: MouseEvent | KeyboardEvent) => onTriggerHandler(value)(event),
            href: href,
            isSelected: props.value ? value.includes(props.value) : undefined,
            disabledMsg,
        })),
    );
    const groupedItems: TreeListItem[] = [];
    props.categories?.forEach((c) => {
        const item = getNestedItems(c, grouped);
        item && groupedItems.push(item);
    });
    return [...notGrouped, ...groupedItems] satisfies TreeListItem[];
});

const treeList = ref<InstanceType<typeof WuxTreeList>>();

// reset tree-list navigation on close
watch(
    () => props.open,
    (newValue, oldValue) => newValue === false && oldValue === true && treeList.value?.onClose(),
);

const onKeydown = (event: KeyboardEvent) => {
    if (!props.open) return;
    treeList.value?.onKeydown(event);

    const onTab = () => {
        onToggled();
    };

    const onEscape = (e: KeyboardEvent) => {
        e.preventDefault();
        onToggled();
    };

    const onSelected = (e: KeyboardEvent) => {
        if (!e.defaultPrevented) {
            e.preventDefault();
            onToggle();
        }
    };

    return ShortcutUtil.getKeyDownHandler(
        [Shortcuts.Tab, onTab],
        [Shortcuts.Tab.shift, onTab],
        [Shortcuts.Escape, onEscape],
        [Shortcuts.Space, onSelected],
        [Shortcuts.Enter, onSelected],
    )(event);
};
</script>

<template>
    <div v-if="props.options.length" v-wux-popover-target="popProps" ref="popoverRef" class="wux-top-bar-dropdown">
        <WuxButton
            ref="buttonRef"
            class="wux-top-bar-dropdown__button"
            :class="[
                props.isDisabled && 'wux-top-bar-dropdown__button--disabled',
                props.open && 'wux-top-bar-dropdown__button--open',
            ]"
            :isDisabled="props.isDisabled"
            :label="currentPopoverLabel"
            variant="text"
            :icon="Icons.chevron_down_small"
            @click="onToggle"
            @keydown="onKeydown"
        />
        <WuxPopover
            v-bind="popoverProps"
            class="wux-top-bar-dropdown__popover"
            :isOpen="props.open"
            alignment="right"
            tabindex="-1"
        >
            <WuxTreeList
                class="wux-top-bar-dropdown__tree-list"
                ref="treeList"
                :items="items"
                :hasShortcutsEnabled="props.open"
            />
        </WuxPopover>
    </div>
</template>

<style lang="scss">
.wux-top-bar-dropdown {
    --wux-tree-list-width: 17.5rem;

    &__button {
        border-radius: 0;
        gap: 0;
        padding: 0 0.5rem;
        flex-direction: row-reverse;
        text-transform: none;
        --_wux-button-font-size: 14px;
        color: var(--wux-color-white);
        &--disabled {
            opacity: 0.7;
        }
        &--open {
            .wux-icon {
                transform: rotate(180deg);
            }
        }
        .wux-icon {
            transition: transform 0.25s;
        }
        &:hover:not(.wux-top-bar-dropdown__button--disabled) {
            background-color: var(--wux-color-white-overlay);
            color: var(--wux-color-white);
        }
        &:focus {
            outline: none;
            border-color: transparent;
        }
        &:focus-visible {
            outline: none;
            border: 1px solid transparent; // needed to avoid a moving effect when the box-shadow is applied/removed
            box-shadow:
                inset 2px 2px 0px var(--wux-color-white),
                inset -2px -2px 0px var(--wux-color-white);
        }
    }
    & .wux-tree-items__item--disabled {
        cursor: default;
    }
    &__tree-list {
        max-width: var(--wux-tree-list-width);
        .wux-tree-items__item {
            padding-top: 0.25em;
            padding-bottom: 0.25em;
        }
    }
    &__popover {
        background-color: var(--wux-color-white);
        border: 1px solid var(--wux-color-selected);
        max-height: 17.5rem; // to match the style of the ScuFlyOut
        // to avoid a strange behavior while scrolling
        top: var(--wux-top-bar-height) !important;
        position: fixed;
        min-width: var(--wux-tree-list-width);
    }
}
</style>
