import { computed, ref, watch } from "vue";

export type UnregisterOutsideClick = () => void;

const registeredListeners = ref(new Map<HTMLElement[], () => void>());
const triggerdOnce = ref<boolean>(false);
const listenerArray = computed(() => Array.from(registeredListeners.value.entries()));

const handleGlobalClick = ({ target }: MouseEvent) => {
    const listenersToTrigger = listenerArray.value.filter(
        ([insideElements]) =>
            !insideElements.some((e) => e === target || (target && e.contains?.(target as HTMLElement))),
    );
    listenersToTrigger.forEach(([insideElements, handler]) => {
        triggerdOnce.value && registeredListeners.value.delete(insideElements);
        handler();
    });
};

watch(
    () => registeredListeners.value.size,
    (hasListeners) => {
        if (hasListeners) {
            document.addEventListener("click", handleGlobalClick);
        } else {
            document.removeEventListener("click", handleGlobalClick);
        }
    },
);

const registerOutsideClick = (
    insideElements: HTMLElement[],
    handler: () => void,
    isTriggerdOnce: boolean = false,
): UnregisterOutsideClick => {
    // We want to register the listener AFTER all current events are processed.
    // Otherwise the outsidelickhandler is immediately triggered by the bubbling event.
    triggerdOnce.value = isTriggerdOnce;
    setTimeout(() => registeredListeners.value.set(insideElements, handler));
    return () => registeredListeners.value.delete(insideElements);
};

export const ClickUtil = {
    registerOutsideClick,
};
