<script lang="ts" setup>
import { MessageOrId, useMsgFormatter } from "../../i18n";

export type WuxSpinnerProps = {
    labelMsg?: MessageOrId;
    type?: "icon" | "component" | "page";
};
const props = withDefaults(defineProps<WuxSpinnerProps>(), { type: "icon" });
const { optM } = useMsgFormatter();
</script>

<template>
    <div v-if="props.type === 'icon'" class="wux-spinner" />
    <div v-else :class="'wux-spinner-container wux-spinner-container--' + props.type">
        <div class="wux-spinner-container__blocker"></div>
        <div class="wux-spinner-container__spinner">
            <div class="wux-spinner" />
            {{ optM(props.labelMsg) }}
        </div>
    </div>
</template>

<style lang="scss">
@use "../../../assets/styles/variables.scss";

.wux-spinner {
    --wux-spinner-size: 22px;
    width: var(--wux-spinner-size);
    height: var(--wux-spinner-size);
    border-radius: 50%;
    position: relative;
    animation: 0.85s cubic-bezier(0.43, 0.35, 0.57, 0.84) 0s infinite normal none running wux-spinner-rotate;

    &::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0;
        border-radius: 50%;
        border: 2px solid var(--wux-color-primary);
        animation: 0.85s cubic-bezier(0.43, 0.35, 0.57, 0.84) 0s infinite normal none running wux-spinner-clip;
    }

    &-container {
        --wux-spinner-blur: blur(12px) brightness(108%);
        --wux-spinner-flex-direction: column;
        z-index: variables.z("blocking-overlay");
        backdrop-filter: var(--wux-spinner-blur);
        display: grid;
        place-items: center;
        position: fixed;
        inset: 0;
        user-select: none;
        cursor: not-allowed;
        text-align: center;

        &--component {
            position: absolute;
        }

        &--page {
            position: fixed;
            .wux-spinner {
                --wux-spinner-size: 3rem;
            }
        }

        &__blocker {
            /*
            I had to create this extra element because setting `pointer-events: none`
            to the container itself does not work. For whatever reason it will negate
            the effects of `user-select and `cursor`.
             */
            pointer-events: none;
            position: absolute;
            inset: 0;
        }

        &__spinner {
            display: flex;
            flex-direction: var(--wux-spinner-flex-direction);
            align-items: center;
            gap: 0.5rem;
        }
    }
}

@keyframes wux-spinner-rotate {
    0% {
        transform: rotate(-300deg);
    }
    100% {
        transform: rotate(60deg);
    }
}

@keyframes wux-spinner-clip {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    100% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
}
</style>
