const NON_PROD_STAGES = ["dev", "tst", "qas"] as const;
type LowercaseStage = (typeof NON_PROD_STAGES)[number];
type Stage = Uppercase<LowercaseStage>;

export const _getCurrentStage = (): Stage | undefined => {
    const hostParts = location.hostname.split(".");
    const firstPart = hostParts[0] as LowercaseStage;
    if (hostParts.length !== 4 || !NON_PROD_STAGES.includes(firstPart)) {
        return undefined;
    }
    return firstPart.toUpperCase() as Stage;
};

// although exported, should be used with a good portion of care as
// we must avoid running different code segments based on stage.
// it may lead to bugs which are extremely hard to predict and debug
export const STAGE = _getCurrentStage();

const COLOR_BY_STAGE: Record<Stage, string> = {
    DEV: "var(--red-5)",
    TST: "var(--orange-5)",
    QAS: "var(--green-5)",
};

export const STAGE_COLOR = STAGE ? COLOR_BY_STAGE[STAGE] : undefined;
