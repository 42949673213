import { diff } from "deep-object-diff";
import { z } from "zod";
import { MessageOrId } from "../i18n";

const DEFAULT_SLEEP_TIME = 1000;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const equals = (a: any, b: any): boolean => {
    if (a === b) return true;
    // the diff tool doesn't work correctly with null / undefined
    // https://github.com/mattphillips/deep-object-diff/issues/29#issuecomment-369334388
    // eslint-disable-next-line eqeqeq
    if (a == null || b == null) return false;
    // for dates it's easier to compare them directly then using the diff tool
    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
    // the diff tool doesn't work correctly with two primitives
    if (a !== Object(a) && b !== Object(b)) return false;
    return Object.keys(diff(a, b)).length === 0;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const includes = (array?: any[], item?: any) => {
    if (!array || item === undefined) {
        return false;
    }
    for (const element of array) {
        if (equals(element, item)) {
            return true;
        }
    }
    return false;
};

const stringToJSONSchema = () =>
    z.string().transform((str, ctx) => {
        try {
            return JSON.parse(str);
        } catch (e) {
            ctx.addIssue({ code: z.ZodIssueCode.custom, message: "Invalid JSON" });
            return z.NEVER;
        }
    });

// polyfill for Promise.withResolvers()
const promiseWithResolvers = <T>(): PromiseWithResolvers<T> => {
    let resolve: ((value: T | PromiseLike<T>) => void) | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let reject: ((reason?: any) => void) | undefined;
    const promise = new Promise<T>((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return {
        promise,
        // the callback always assigns these values
        resolve: resolve!,
        reject: reject!,
    };
};

const promiseWithTimeout = async <T>(promise: Promise<T>, time: number): Promise<T> => {
    let timer: NodeJS.Timeout;
    return Promise.race<T>([promise, new Promise((_r, rej) => (timer = setTimeout(rej, time)))]).finally(() =>
        clearTimeout(timer),
    );
};

/**
 * Debounce the call to a function: It will be called after the wait time when it was not called again in this time.
 * @param func function that should be debounced
 * @param wait maximum wait time in ms
 * @returns an async function which will only call `func` after the wait time has elapsed.
 * Each call to the debounced function will reset the waiting period
 */
const debounce = <TArgs extends unknown[], TResult>(
    func: (...args: TArgs) => Promise<TResult> | TResult,
    wait: number,
) => {
    let resolvers: ((result: Promise<TResult> | TResult) => void)[] = [];
    const resolveAll = (result: Promise<TResult> | TResult) => {
        resolvers.forEach((resolve) => resolve(result));
        resolvers = []; // Reset for future calls
    };
    let timeout: number;
    return (...args: TArgs) => {
        clearTimeout(timeout); // i.e. reset the waiting period each time the inner function is called
        const { promise, resolve } = promiseWithResolvers<TResult>();
        resolvers.push(resolve);
        timeout = window.setTimeout(() => resolveAll(func(...args)), wait); // Once waiting period complete, resolve all previous calls
        return promise;
    };
};

const copyToClipboard = (content: string): Promise<void> =>
    navigator.clipboard
        .write([
            new ClipboardItem({
                "text/plain": new Blob([content], { type: "text/plain" }),
            }),
        ])
        .catch(() => undefined);

const isMacOS = (): boolean => navigator.userAgent.includes("Mac OS X");

/**
 * This utility is used to detect mobile device specifically targeting Android and certain tablets like Zebra devices (E40, E45).
 */
const isMobile = (): boolean => {
    const hasTouchScreen = "ontouchstart" in window || navigator.maxTouchPoints > 0;
    const isSmallScreen = window.screen.width <= 1282;
    // Based on 1280x800 screen size of the current current Zebra devices (e.g. E40, E45)

    return isSmallScreen && hasTouchScreen;
};

/** This function generates a prefixed action name for a given "ddName" (data dictionary name) or "MessageOrId" object.
 * The prefix "dd.action-name" is significant as it indicates actions already configured by the teams.
 * If no "ddName" is provided, it returns an empty string.
 * Note: We send the translation key to DataDog, not the translated message, to maintain data privacy and consistency across multiple locales.
 */
const getDDActionName = (ddName?: string | MessageOrId): string => {
    if (!ddName) return "";
    const name = typeof ddName === "string" ? ddName : ddName.id;
    return `dd.action-name.${name}`;
};

/**
 * This callback can be used to sleep for `ms` milliseconds. If no parameter is provided, it'll sleep for 1 second.
 */
const sleep = async (ms: number = DEFAULT_SLEEP_TIME): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));

const range = (n: number) =>
    Array(n)
        .fill(undefined)
        .map((_, i) => i);

export const ComponentUtils = {
    equals,
    includes,
    stringToJSONSchema,
    promiseWithResolvers,
    promiseWithTimeout,
    debounce,
    copyToClipboard,
    isMacOS,
    isMobile,
    sleep,
    range,
};

export const DatadogActions = {
    getName: getDDActionName,
};
