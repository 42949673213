<script lang="ts" setup>
import { __, useMsgFormatter } from "../../i18n";
import { Icons } from "../../icons";
import WuxBottomBar from "../WuxBottomBar/WuxBottomBar.vue";
import WuxButton from "../WuxButton/WuxButton.vue";
import WuxIcon from "../WuxIcon/WuxIcon.vue";

type Props = {
    title: string;
};

const props = defineProps<Props>();

const { m } = useMsgFormatter();

const emit = defineEmits<{
    (e: "start"): void;
    (e: "skip"): void;
}>();
</script>

<template>
    <Teleport to="body">
        <div class="wux-guided-tour-first-step hidden-print">
            <div class="wux-guided-tour-first-step-card">
                <div class="wux-guided-tour-first-step-card__header">
                    <WuxIcon :src="Icons.circle_information" :size="56" />
                    {{
                        m({
                            id: "ui.components.guided-tour.intro.title",
                            values: { title: props.title },
                        })
                    }}
                </div>
                <div class="wux-guided-tour-first-step-card__content">
                    {{ m("ui.components.guided-tour.intro.content") }}
                </div>
                <WuxBottomBar isComponentLevel>
                    <WuxButton
                        type="button"
                        variant="text"
                        @click="emit('skip')"
                        :labelMsg="__('ui.components.guided-tour.skip')"
                    />
                    <WuxButton
                        type="button"
                        isPrimary
                        variant="text"
                        @click="emit('start')"
                        :labelMsg="__('ui.components.guided-tour.start')"
                    />
                </WuxBottomBar>
            </div>
        </div>
    </Teleport>
</template>

<style lang="scss">
@use "../../../assets/styles/variables.scss";

.wux-guided-tour-first-step {
    position: absolute;
    background-color: white;
    margin: 0.2rem;
    z-index: variables.z("guided-tour");
    bottom: 2rem;
    right: 2rem;
    box-shadow:
        0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.wux-guided-tour-first-step-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 20rem;
    max-width: 50dvw;

    &__header {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.6rem;
        padding: 1rem 1.5rem 0rem 1.5rem;
        display: flex;
        align-items: center;
        gap: 2.25rem;

        > .wux-icon {
            color: var(--wux-color-primary);
        }
    }

    &__content {
        padding: 1rem 1.75rem 1.5rem 1.75rem;
    }
}
</style>
