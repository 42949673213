<script setup lang="ts">
import { computed } from "vue";
import { useMsgFormatter, WuxAccordion, WuxAccordionPanel, WuxButton } from "@ui/components";
import { ErrorDialogState } from "../Dialog";

type ErrorDialogProps = {
    errors: ErrorDialogState;
};
const props = defineProps<ErrorDialogProps>();
const { optM } = useMsgFormatter();
// The errors will appear sorted according to their last occurrence
const sortedErrors = computed(() =>
    Array.from(props.errors).sort((a, b) => b[1].timeOfOccurrence - a[1].timeOfOccurrence),
);
</script>

<template>
    <div class="errors-template">
        <template v-for="[errorId, error] in sortedErrors" :key="errorId">
            <WuxAccordion>
                <WuxAccordionPanel isExpanded>
                    <template #accordion-custom-head>
                        <div class="errors-template__header">
                            <div class="errors-template__header__title">
                                ({{ error.numberOfOccurrences }}) {{ optM(error.headerMsg) }}
                            </div>
                            <div v-if="sortedErrors.length > 1">
                                <WuxButton
                                    variant="text"
                                    type="button"
                                    isPrimary
                                    :labelMsg="error.closeMsg"
                                    @click="error.onClose"
                                    isDense
                                />
                            </div>
                        </div>
                    </template>
                    <span class="errors-template__content">
                        {{ optM(error.contentMsg) }}
                        <component v-if="error.content" :is="error.content?.component" />
                    </span>
                </WuxAccordionPanel>
            </WuxAccordion>
        </template>
    </div>
</template>

<style lang="scss">
.errors-template {
    margin-bottom: 0.5rem;
    max-height: 20rem;
    overflow: auto;

    &__header {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        &__title {
            flex: 1;
        }
    }
}
</style>
